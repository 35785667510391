import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse } from "shared/types/server";
import type { ProductModelPayload, ProductModelResponse } from "./types";

const queryKey = (payload: ProductModelPayload) =>
  [baseQueryKeys.product, "model", payload] as const;

const productModelApi = {
  getModels: async ({ brandCode, 대분류 }: ProductModelPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<ProductModelResponse>>(
      api.product.models,
      { params: { brandCode, categoryCode: 대분류 } }
    );

    return response;
  },
};

export function useProductModelsQuery(payload: ProductModelPayload) {
  return useQuery({
    queryKey: queryKey(payload),
    queryFn: () => productModelApi.getModels(payload),
    enabled: Boolean(payload.brandCode && payload.대분류),
    select: data => data?.modelList,
    retry: false,
  });
}
