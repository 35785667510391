const _1MB = 1 * 1024 * 1024; // 1MB

/** 이미지 크기를 줄이는 함수 */
const reduceImageSize = (
  img: HTMLImageElement
): { width: number; height: number } => {
  const maxSize = 2000;
  const { width, height } = img;

  const isOverSize = width > maxSize || height > maxSize;
  if (!isOverSize) return { width, height };

  return width > height
    ? { width: maxSize, height: (height / width) * maxSize }
    : { width: (width / height) * maxSize, height: maxSize };
};

/** 이미지 품질을 압축하는 함수 */
const compressQuality = (
  blob: Blob,
  targetSize: number,
  canvas: HTMLCanvasElement,
  fileType: string
): Promise<Blob> => {
  return new Promise(resolve => {
    const currentQuality = 0.9; // 초기 압축 품질 설정
    const step = 0.05; // 압축 품질 감소 단계

    const tryCompress = (quality: number): void => {
      canvas.toBlob(
        compressedBlob => {
          if (!compressedBlob) {
            resolve(blob); // 압축 실패 시 원본 반환
            return;
          }

          if (compressedBlob.size < targetSize || quality <= 0.1) {
            resolve(compressedBlob); // 목표 크기 도달
          } else {
            tryCompress(quality - step);
          }
        },
        fileType,
        quality
      );
    };

    tryCompress(currentQuality);
  });
};

/** 파일을 이미지로 로드하는 함수 */
const loadImage = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result as string;
    };

    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error("이미지 로드 실패"));
    reader.onerror = () => reject(new Error("파일 읽기 실패"));

    reader.readAsDataURL(file);
  });
};

/**
 * 파일을 압축하는 함수입니다.
 *
 * 이 함수는 주어진 이미지 파일의 크기를 줄이고, 지정된 목표 크기 이하로 압축된 파일을 반환합니다.
 * 만약 파일 크기가 이미 목표 크기보다 작으면, 원본 파일을 그대로 반환합니다.
 */
export const compressImageFile = async (
  file: File,
  targetSize = _1MB
): Promise<File> => {
  if (file.size < targetSize) {
    return file;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas context를 생성하지 못했습니다.");
  }

  const img = await loadImage(file);
  const { width, height } = reduceImageSize(img);
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  const compressedBlob = await compressQuality(
    file,
    targetSize,
    canvas,
    file.type
  );

  return new File([compressedBlob], file.name, { type: file.type });
};

/** 여러 파일을 압축하는 함수 */
export const compressImageFiles = async (files: File[]): Promise<File[]> => {
  return Promise.all(files.map(file => compressImageFile(file)));
};
